<template>
  <div class="step-2">
    <h4 class="title text-uppercase text-center mb-4">Orientações</h4>

    <p class="paragraph text-center mb-4">
      Para iniciar a prova você deve ter assistido todas as aulas deste módulo.
      <br />
      <br />
      Ao clicar em Começar você confirma que já assistiu todas as aulas<br />
      e está ápto para realizar a prova
      <br />
      <br />
      É permitido consulta <strong>somente</strong> a suas anotações e ao livro.
      <br />
      <br />
      A prova não tem tempo para realização, porém, ao clicar em começar<br />
      você deve iniciar e finalizar a prova.
      <br />
      <br />
      Caso feche esta página, terá que respondê-la toda novamente.
      <br />
      <br />
      <strong>{{ expire_msg }}</strong>
    </p>

    <div v-if="isAvailabe" class="d-flex justify-content-center">
      <button key="btn-2" class="btn btn-primary px-5" @click="$emit('next')">
        COMEÇAR
      </button>
    </div>
  </div>
</template>
<script>
import get from "lodash/get";
import moment from "moment";

export default {
  props: {
    module: {
      type: Object,
      default: () => {}
    },
    classInfo: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    isAvailabe() {
      const expire_at = get(this.classInfo, "exam_expire_at", false);
      if (expire_at) {
        const diff =
          moment(expire_at, "DD/MM/YYYY").unix() - new Date().getTime();
        return diff < 0;
      }

      return false;
    },
    expire_msg() {
      const expire_at = get(this.classInfo, "exam_expire_at", false);

      if (expire_at) {
        return `Esta prova ficará disponível até: ${expire_at}`;
      }

      return "Indisponível para resposta";
    }
  }
};
</script>
