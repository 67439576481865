<template>
  <action-dispatcher
    action="questions/get"
    :parameters="$route.params.id"
    @success="success"
  >
    <div class="view-test-exam">
      <h3 class="text-center mb-4">{{ moduleName }}</h3>

      <div v-if="sortedQuestions.length == 0" class="empty-msg">
        <p class="paragraph text-center text-secondary mb-4">
          Sem perguntas cadastradas até o momento!
        </p>
      </div>

      <step-01
        v-else-if="step == 1"
        @next="next(2)"
        :module="currentModule"
        :classInfo="currentClass"
      />

      <step-02
        v-else-if="step == 2"
        :questions="sortedQuestions"
        :loading="loading"
        :answers="answers"
        @submit="submit"
      />

      <thanks v-else-if="step == 4" />
    </div>
  </action-dispatcher>
</template>
<script>
import Step01 from "@/components/Exam/Step01";
import Step02 from "@/components/Exam/Step02";
import Thanks from "@/components/Exam/Thanks";
import get from "lodash/get";
export default {
  components: { Step01, Step02, Thanks },
  data() {
    return {
      step: 1,
      currentModule: null,
      currentClass: null,
      loading: false,
      questions: [],
      answers: []
    };
  },
  mounted() {
    this.$store
      .dispatch("content/getModule", this.$route.params.id)
      .then(data => {
        this.currentModule = data;
      });
    this.$store
      .dispatch("classes/getClass", this.$route.params.class_id)
      .then(data => {
        this.currentClass = data;
      });
  },
  computed: {
    moduleName() {
      return get(this.currentModule, "name", "");
    },
    sortedQuestions() {
      return [...this.questions].sort((a, b) => a.sort - b.sort);
    }
  },
  methods: {
    success(data) {
      this.questions = data;
      this.answers = data.reduce(
        (acc, curr) => ({ ...acc, [curr.id]: curr }),
        {}
      );
    },
    next(index) {
      this.step = index;
    },
    submit() {
      if (this.loading) return;

      const answers = Object.entries(this.answers).map(([_, v]) => v);

      const payload = {
        name: this.$user.user.name,
        email: this.$user.user.email,
        user_id: this.$user.user.id,
        module: this.$route.params.id,
        answers,
        created_at: new Date().toISOString()
      };

      this.loading = true;

      this.$store
        .dispatch("exam/submit", payload)
        .then(() => {
          this.$message.success(
            "Prova enviada com sucesso, espere até um dos tutores ou monitores divulgarem o resultado."
          );
          this.$router.push({
            name: "exams"
          });
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
<style lang="scss" scoped>
.view-test-exam {
}
</style>
