<template>
  <div class="component-exam-step-02">
    <div class="d-flex justify-content-center mb-4">
      <div class="paginator-wrapper">
        <div
          v-for="(q, i) in questions"
          :key="i + 'paginator'"
          class="paginator-item"
        >
          <div
            class="circle mb-1"
            :class="{
              answered: i < question_index,
              active: question_index == i
            }"
          >
            <template v-if="i < question_index">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="5"
                height="4"
                viewBox="0 0 5 4"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M4.17749 0.803566L1.7807 3.73456L0.0803977 2.18854L0.672095 1.5378L1.68659 2.46024L3.49663 0.246792L4.17749 0.803566Z"
                  fill="#E2B352"
                />
              </svg>
            </template>
          </div>
          <span
            class="number"
            :class="{
              answered: i < question_index,
              active: question_index == i
            }"
            >{{ i + 1 }}</span
          >
        </div>
      </div>
    </div>

    <h6 class="question-title mb-4">
      {{ questions[question_index].title }}
    </h6>

    <div
      v-if="questions[question_index].type == 'alternative'"
      class="d-flex flex-column"
    >
      <div
        v-for="a in questions[question_index].alternatives"
        :key="a.id"
        class="mb-4"
      >
        <div class="form-check form-check-inline me-4">
          <input
            v-model="answers[questions[question_index].id].answer"
            class="form-check-input"
            type="radio"
            :id="a.id"
            :value="a.id"
          />
          <label class="form-check-label text-uppercase" :for="a.id">{{
            a.text
          }}</label>
        </div>
      </div>
    </div>
    <div v-else>
      <form-group
        id="answer"
        textarea
        v-model="answers[questions[question_index].id].answer"
        type="textarea"
        label="Resposta*"
        placeholder="Digite sua resposta aqui"
        rows="20"
      />
    </div>

    <div class="row align-items-center justify-content-center mt-4">
      <div class="col-lg-6 order-1 order-lg-0">
        <button
          v-if="question_index > 0"
          class="btn btn-outline-secondary btn-sm btn-block"
          key="btn-3"
          @click="nextQuestion(-1)"
        >
          <i class="icon-angle-left mr-2"></i>
          Voltar
        </button>
      </div>
      <div class="col-lg-6 order-0 order-lg-1 mb-4 mb-lg-0">
        <button
          key="btn-4"
          v-if="question_index < questions.length - 1"
          class="btn btn-outline-primary btn-sm btn-block"
          @click="nextQuestion(1)"
        >
          Avançar
          <i class="icon-angle-right ml-2"></i>
        </button>
        <button
          v-else
          key="btn-5"
          class="btn btn-primary btn-sm btn-block"
          @click="$emit('submit')"
        >
          <b-spinner v-if="loading" small variant="light" />
          <span v-else>Enviar</span>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["questions", "answers", "moduleName", "loading"],
  data() {
    return {
      question_index: 0
    };
  },
  methods: {
    nextQuestion(factor) {
      if (!this.questions[this.question_index]) return;

      var id = this.questions[this.question_index].id;

      if (!this.answers[id].answer) {
        this.$message.warning(
          "Por favor, antes de avançar responda a pergunta!"
        );
        return;
      }

      this.question_index += factor;
    }
  }
};
</script>
<style lang="scss" scoped>
.component-exam-step-02 {
  .paginator-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: fit-content;

    @media screen and (max-width: 991px) {
      flex-wrap: wrap !important;
    }

    &::before {
      content: "";
      position: absolute;
      top: 5px;
      left: 0;
      width: 100%;
      height: 2.3px;
      background: var(--secundria-cinza, #6b627730);
      z-index: 0;
      @media screen and (max-width: 991px) {
        display: none;
      }
    }

    .paginator-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      z-index: 1;
      margin-right: 0.25rem;
      margin-left: 0.25rem;

      @media screen and (max-width: 991px) {
        margin-right: 0.15rem;
        margin-left: 0.15rem;
        margin-bottom: 0.25rem;
      }

      .circle {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: #fff;
        border: 2px solid #6b627730;

        display: flex;
        align-items: center;
        justify-content: center;

        &.active {
          border: 1px solid #e2b352;
          background: #e2b352;
        }

        &.answered {
          background: #fff;
          border: 2px solid #e2b352;
        }
      }

      .number {
        color: var(--Cinza, #8e908f);
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;

        &.active {
          color: var(--Azul-Escuro-2, #003550);
          font-weight: 700;
        }

        &.answered {
          color: var(--Cinza, #8e908f);
          font-weight: 700;
        }
      }
    }
  }

  .form-check-label {
    @media screen and (max-width: 991px) {
      font-size: 12px;
    }
  }
}
</style>
